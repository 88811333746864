.table-border {
    border: 1px solid var(--cor-primary);
    border-radius: 10px;
    box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.246);
}

.button-table-red {
    padding: 4px;
    max-width: 50px;
    background-color: #ff3939;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    border: none;
}

.button-table-red:hover {
    background-color: #ff3939b2;
}

.button-table-yellow {
    padding: 4px;
    max-width: 50px;
    background-color: #f7ac00;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    border: none;
}

.button-table-yellow:hover {
    background-color: #f7ad00b2;
}

.table-responsive {
    overflow-x: auto;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    font-size: 14px;
    color: var(--cui-table-color-state, var(--cui-table-color-type, var(--cui-table-color)));
    background-color: var(--cui-table-bg);
    border-bottom-width: var(--cui-border-width);
    box-shadow: inset 0 0 0 9999px var(--cui-table-bg-state, var(--cui-table-bg-type, var(--cui-table-accent-bg)));
}