.footer-bg {
    background-image: url('../../img/footer/footer-bg.png');
    background-size: cover;
    background-position: 0% 100%;
    background-repeat: no-repeat;

    .footer {
        justify-content: center;
        display: grid;
        background: none;
        border: none;
        padding: 150px 0px 50px 0px;
        gap: 10px;
        text-align: center;

        .logo {
            display: grid;
            justify-content: center;

            img {
                padding-bottom: 20px;
            }

            .line {
                justify-self: center;
                width: 150px;
                height: 2px;
                background-color: var(--cor-secondary);
            }
        }

        .address {
            padding-top: 20px;

            p {
                font-size: 18px;
                font-weight: 500;
                color: var(--cor-primary);
            }
        }

        .rights {
            p {
                color: var(--cor-primary);
                font-weight: 400;
            }

            a {
                text-decoration: none;
                color: var(--cor-primary);
                font-weight: 600;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .footer {
        padding: 50px 20px 50px 20px !important;

        .logo {
            img {
                width: 200px;
            }
        }

        .address {
            p {
                font-size: 16px !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-bg {
        .footer {
            backdrop-filter: blur(5.5px);
            background-color: hsla(0, 0%, 100%, 0.349);
            padding: 100px 20px 50px 20px;

            .address {
                p {
                    font-size: 16px !important;
                }
            }

            .rights {
                p {
                    font-size: 14px !important;
                    font-weight: 500;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 100px 20px 20px 20px;
    }
}

@media screen and (max-width: 425px) {
    .footer-bg {
        .footer {
            .logo {
                img {
                    width: 160px;
                }
            }

            .address p {
                font-size: 14px !important;
            }
        }
    }
}