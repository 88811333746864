#modal {

    .modal-body {
        padding: 2rem 5rem;
    }

    .title-modal {
        color: var(--cor-secondary);
        padding: 10px;
        font-weight: 600;
        font-size: 22px;

        text-align: center;
    }

    .title-modal-delete {
        background-color: #B70000;
        color: #fff;
        font-weight: 600;
        font-size: 20px;

        padding: 15px;
        border-radius: 5px;
        width: 100%;

        text-align: center;
    }

    .form-label {
        color: var(--cor-primary);

    }

    .form-control {
        border: 2px solid var(--cor-secondary);
    }

    .input-img {
        display: flex;
        flex-direction: column;

        a {
            background-color: var(--cor-secondary);
            width: 140px;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            padding: 5px;
            margin-top: 10px;
            cursor: pointer;
        }
    }

    input#imagem {
        width: 100%;
        background-color: #F5F5F5;
        border-radius: 5px;
        border: none;
    }

    .custom-file-upload {
        border: none;
        display: inline-block;
        padding: 8px 12px;
        cursor: pointer;
        background-color: #dedede;
        color: #495057;
        border-radius: 5px;
        transition: background-color 0.3s ease;
    }

    .custom-file-upload:hover {
        background-color: #e9ecef;
    }

    .button-modal {
        width: 100%;
        background-color: var(--cor-primary);
        color: #fff;
        border: none;
    }

    .button-modal-cancel {
        width: 100%;
        background-color: #787878;
        color: #fff;
        border: none;
    }

    h3.delete {
        color: var(--cor-secondary);
        font-size: 22px;
        text-align: center;
        margin: 20px;
    }

    p.delete {
        text-align: center;
        color: #565656;
    }

    .text-quill {
        height: 100%;
        margin-bottom: 1rem;

        border-radius: 5px;
        border: solid 2px var(--cor-secondary);

        p {
            text-align: left;
            font-size: 16px;
            color: #000;
        }
    }

    .postImageContainer {
        border-radius: 5px;
        background-color: #0000001d;

        img {
            height: 30rem;
            object-fit: contain;
        }
    }

    .postImageContainer+p {
        margin-top: 1rem;
    }



}

.ql-toolbar.ql-snow {
    overflow-y: scroll;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
}

.ql-container.ql-snow {
    border: none;
}

.modal-header {
    border-bottom: none;
}