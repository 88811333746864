.quill{

    img, iframe{
        display: block;
        margin: 0 auto;
    }

    iframe{
        width: 90%;
        height: 350px;
        border-radius: 10px;
    }
}