.navbar {
    transition: .5s ease;
    z-index: 1000 !important;
    background-color: white;
}

.nav-container {
    padding: 0px 126px;
    width: 100%;
    display: flex;
}

.container-fluid {
    padding: 0px;
    display: flex;
}

.flex-content-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    padding: 0rem 2rem;

    .btn-danger {
        color: var(--cor-white);
    }
}

.navbar-toggler {
    float: right;
}

.scrolled {
    background-color: #093490ea;
}

.offcanvas-body {
    display: flex;
    padding: 10px;
}

.flex-button-nav {
    display: flex;
    border-radius: 5px;
}

.btn-group {
    padding: 0px 16px;
    transition: 0.2;
}

.btn-group .btn {
    padding: 8px;
    border: 1px solid #ffffff00;
}

.btn-group:hover .btn {
    color: var(--cor-secondary);
    border-radius: 5px;
}

.blogLink {
    background-color: var(--cor-primary);
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;

    background-color: #fff;

    z-index: 1;
}

.navbar-nav {
    gap: 20px;
}

.navLogo {
    padding: 0px;
    margin: 0px;
}

.nav-link {
    padding: 0px;
}

a.nav-link.active {
    color: #fff !important;
    font-size: 14px;
    padding: 2px;
}

a.nav-link.active:hover {
    color: var(--cor-secondary) !important;
}

.btn-group .btn {
    background: none;

    color: white;
}

button {
    margin: 0px !important;
    border: none;
}

.nav-item {
    align-self: center;
}

.trackLink {
    background-color: var(--cor-secondary);
    border: none;
    transition: 0.5s ease;
}

.trackLink:hover {
    background-color: #c54f00;
}

button.clientLink {
    border: #fff solid 1px;
}

.clientLink {
    background-color: var(--cor-secondary);
    color: #fff;
    font-size: 14px;
    transition: .5s ease;
}

.clientLink:hover {
    background-color: #f67b29;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    background-image: url('../../icon/menu2.png');
}

.navLogo2 {
    display: none;
}

a.aling-sair {
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 1600px) {
    .nav-container {
        margin: 0px;
        padding: 0px 20px;
    }
}

@media screen and (max-width: 1399px) {
    .navbar {
        padding: 150px 5%;
    }

    .container-fluid {
        justify-content: space-between;
        padding: 0px;

        .navLogo2 {
            display: block;
        }
    }

    .flex-content-nav {
        grid-template-columns: 100%;
        padding-top: 50px;
        display: grid;
        height: fit-content;
        row-gap: 10px;
    }

    .nav-container {
        padding: 0px;
    }

    .navLogo {
        display: none;
    }

    .nav-item {
        display: grid;
    }

    .navbar-toggler {
        padding: 0px;
    }
}

@media screen and (max-width: 425px) {
    .container-fluid {
        padding: 0px;
    }
}