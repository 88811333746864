.mediaButtons{
    z-index: 100;
    position: fixed;
    bottom: 5px;
    left: 0px;

    display: grid;
    grid-template-areas: 'media';
    
    align-items: center;
    justify-content: center;

    padding: 90px 90px 90px 20px;

    border-radius: 50%;
    transition: .5s ease;

    button{
        z-index: 100;
        grid-area: media;
        border: none;
        background: none;
        display: grid;

        .mais{
            justify-self: center;
            align-self: center;
            transition: 0.5s ease-in-out;
            filter: grayscale(0%);
        }
    }

    .link{
        grid-area: media;
        justify-self: center;
        align-self: center;

        position: relative;

        background-color: var(--cor-primary);
        border-radius: 50px;
        padding: 8px;

    }
    .youtube{
        opacity: 0%;
        transition: .3s ease-in-out;
    }
    .insta{
        opacity: 0%;
        transition: .3s .05s ease-in-out;
    }
    .whats{
        opacity: 0%;
        transition: .3s .1s ease-in-out;
    }
    .linkedin{
        opacity: 0%;
        transition: .3s .15s ease-in-out;
    }
    .face{
        opacity: 0%;
        transition: .3s .2s ease-in-out;
    }

}
.mediaButtons:hover{

    .mais{
        filter:grayscale(20%);
        scale: .9;
    }
    .youtube{
        pointer-events: all;
        opacity: 100%;
        transform: translate(30px, -80px);
    }
    .insta{
        opacity: 100%;
        transform: translate(75px, -49px);

        a{
            display: block;
        }
    }
    .whats{
        opacity: 100%;
        transform: translateX(90px);

        a{
            display: block;
        }
    }
    .linkedin{
        opacity: 100%;
        transform: translate(75px, 49px);

        a{
            display: block;
        }
    }
    .face{
        opacity: 100%;
        transform: translate(30px, 80px);

        a{
            display: block;
        }
    }
}
