.alertModal {
    
    position: fixed;
    z-index: 9999;
    bottom: 2rem;
    right: 2rem;
    

    img{
        margin-right: 10px;
    }
}