@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Book.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Steppe';
  src: url('../../../public/fonts/Steppe-Black.ttf') format('truetype');
  font-weight: 900;
}

* {
  scroll-behavior: smooth !important;
  text-decoration: none !important;
  font-family: 'Steppe';
  list-style: none;
}

body {
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  opacity: 0%;
}

::-webkit-scrollbar-thumb {
  background-color: var(--cor-tertiary);
}

::-webkit-scrollbar-track {
  background-color: #002a7200;
}

:root {
  --cor-primary: #093390;
  --cor-secondary: #FF6700;
  --cor-tertiary: #002972;
  --cor-white: #fff;
}


h1 {
  font-size: 30px !important;
}

@media screen and (max-width: 1600px) {
  h1 {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 425px) {
  body {
    overflow-x: hidden;
  }

  h1 {
    font-size: 22px !important;
  }
}