#login-blog {

    .icon {
        position: fixed;
        top: 10px;
        left: 10px;
        color: #fff;

        z-index: 2;
    }

    .align {
        display: flex;
    }

    .identifier {
        color: white;
        position: relative;
        margin: auto auto 0px auto;
        text-align: center;
        display: flex;
        z-index: 999;

        p {
            font-size: 14px;
        }

        a {
            opacity: 100%;
            color: white;
        }
    }

    .img {
        background-image: linear-gradient(0deg, #ff660085, #002a7266, #093490b4), url(../../img/blog/img-blog2.jpg);
        width: 80%;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;

        display: grid;
        align-items: center;
        justify-content: center;

        img {
            filter: brightness(100);
            position: relative;
            top: 40%;
        }
    }

    img.img1 {
        position: absolute;
        bottom: 0px;
        width: 110%;
    }

    img.img2 {
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;

        width: 100%;
    }

    form {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: space-around;
        align-items: center;

        z-index: 1;
    }

    form p {
        padding: 1rem 4rem;
    }

    .form-control {
        border: 2px solid var(--cor-secondary);
        width: 450px;
    }

    .form-label {
        color: var(--cor-primary);
        font-weight: 600;
    }

    h1 {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: var(--cor-primary);
    }

    p {
        text-align: center;
        padding: 10px;
    }


    button {
        background-color: var(--cor-primary);
        color: #fff;
        border: none;
        width: 450px;
    }

    .align-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 800px) {

    #login-blog .img {
        display: none;
    }

    #login-blog .align {
        flex-direction: column;
    }

    #login-blog .form-control {
        width: 300px;
    }

    #login-blog button {
        width: 300px;
    }
}

@media screen and (max-width: 300px) {


    #login-blog .form-control {
        width: 220px;
    }

    #login-blog button {
        width: 220px;
    }
}