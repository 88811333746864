#newsletter {

    background-image: url(../../img/blog/newsletter.png);

    .blur {
        backdrop-filter: blur(5.5px);
        background-color: hsla(0, 0%, 100%, 0.702);
        width: 100%;

        padding: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    h1 {
        color: var(--cor-primary);
        font-weight: 600;
    }

    .division {
        height: 95px;
        width: 3px;
        background-color: var(--cor-secondary);
        margin: 5px;
    }

    .form-control {
        border: solid 2px var(--cor-secondary);
        width: 350px;
        background-color: #ffffff94;
    }

    button {
        background-color: var(--cor-primary);
        color: var(--cor-secondary);
        width: 350px;
    }

    .form-label {
        color: #000;
    }
}

@media screen and (max-width: 600px) {

    #newsletter {
        .blur {
            flex-direction: column;
            gap: 30px;
        }

        .division {
            height: 0px;
            width: 0px;
            margin: 0px;
        }
    }
}