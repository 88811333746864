@keyframes slideToRight{
    0%{
        opacity: 0%;
        transform: translateX(-100%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateX(0%);
    }
}

@keyframes slideToLeft{
    0%{
        opacity: 0%;
        transform: translateX(100%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateX(0%);
    }
}

@keyframes slideToDown{
    0%{
        opacity: 0%;
        transform: translateY(-100%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes slideToUp{
    0%{
        opacity: 0%;
        transform: translateY(100%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes halfToRight{
    0%{
        opacity: 0%;
        transform: translateX(-50%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateX(0%);
    }
}

@keyframes halfToLeft{
    0%{
        opacity: 0%;
        transform: translateX(50%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateX(0%);
    }
}

@keyframes halfToDown{
    0%{
        opacity: 0%;
        transform: translateY(-50%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes halfToUp{
    0%{
        opacity: 0%;
        transform: translateY(50%);
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes changeOpacity{
    0%{
        opacity: 0%;
    }
    30%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
    }
}

@keyframes slideBackground{
    0%{
        background-position: 88% 45%;
    }
    60%{

    }
    100%{
        opacity: 100%;
        background-position: 85% 45%;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: -4px 8px 22px 0px rgba(115, 0, 0, 0.18);
    }

    100% {
        transform: scale(1.05);
        box-shadow: -4px 8px 32px 0px rgba(115, 0, 0, 0.3);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeDown{
    0%{
        height: 0%;
    }
    100%{
        opacity: 100%;
        height: 100%;
    }
}

@keyframes rota {
    100%{
        transform: rotate(-360deg);
    }
}

@keyframes rota2 {
    100%{
        transform: rotate(360deg);
    }
}

@keyframes fadeOutInOut{
    0%{
        opacity: 30%;
    }
    50%{
        opacity: 80%;
    }
    100%{
        opacity: 30%;
    }
}
@keyframes fadeOutInOut2{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 90%;
    }
    100%{
        opacity: 0%;
    }
}

@keyframes shake {
    0%{
        transform: rotate(10deg);
    }
    20%{
        transform: rotate(-360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes events {
    100%{
        pointer-events: all !important;
    }
    
}

@keyframes slideToRight2{
    0%{
        left: 200px;
        opacity: 0%;
        transform: translateX(-100%);
    }
    50%{
        left: 0px;
        opacity: 0%;
    }
    100%{
        left: 0%;
        opacity: 100%;
        transform: translateX(0%);
    }
}
