.spinner {

    width: 48px;
    height: 48px;
    border: 5px solid #8f8f8f;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    margin-top: 50px;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}